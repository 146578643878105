
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    // import "intl-pluralrules";
import Head from "next/head";
import "../styles/globals.css";
import "../store";
import { removeServiceWorker } from "../pwa";
import { websocket } from "../services/websocket";
import Navigation from "../navigation/Navigation";
import Footer from "../navigation/Footer";
import { signInStatus } from "../ducks/signIn";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import HomeIE from "../modules/HomeIE";
import { pageview } from "../lib/ga";
import { Provider } from "hooks-for-redux";
import { setLoadingBar, setPlaywire } from "../ducks/siteData";
import { ErrorBoundary } from "react-error-boundary";
import { ERROR_BOUNDRY, sendPingData, setError, setErrorMsg, } from "../ducks/ping";
import Popup from "../components/Popup";
import { chooseGame, setTab } from "../ducks/lobby";
import { setBoardSize, setSetting } from "../ducks/settings";
import { CREATE_GAME_TAB, ChessSets, DAILY_TAB, LIVE_GAME_TAB, ModeIds, TimeControl, VARIANT_CHESS, } from "@chesshotel/constants";
import { getModeId } from "../util/util";
// import { loadAds } from "../loadAds";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbook } from "../util/growthbook";
import { initWebPush } from "../ducks/webPush";
import Script from "next/script";
import { consentListener } from "../loadScripts";
import { loadAds } from "../loadAds";
declare global {
    interface Window {
        gtag: any;
        googlefc: any;
        adsbygoogle: any;
        AppleID: any;
        fbAsyncInit: any;
        Stripe: any;
        __cmp: any;
        setCookie: any;
        __tcfapi: any;
        google: any;
        chesshotel: any;
        chesshotelPuzzle: any;
        fusetag: any;
        FB: any;
        dataLayer: any;
        google_optimize: any;
        ramp: any;
        yaContextCb: any;
        Ya: any;
        _pwGA4PageviewId: any;
        workbox: any;
        userHasInteracted: boolean;
        webkitAudioContext: any;
    }
}
function initLobbyChosenGame() {
    try {
        const timeVal = localStorage.getItem("chosenTime");
        const incVal = localStorage.getItem("chosenInc");
        const variantVal = localStorage.getItem("chosenVariant");
        const turnLengthVal = localStorage.getItem("turnLength");
        const timeControlVal = localStorage.getItem("timeControl");
        const time = timeVal ? parseInt(timeVal) : 10;
        const inc = incVal ? parseInt(incVal) : 0;
        const variant = variantVal ? parseInt(variantVal) : VARIANT_CHESS;
        const timeControl = timeControlVal
            ? parseInt(timeControlVal)
            : TimeControl.LIVE;
        const turnLength = turnLengthVal ? parseInt(turnLengthVal) : 2;
        if (timeControl == TimeControl.LIVE) {
            if (typeof window !== "undefined") {
                if (window.innerWidth <= 768) {
                    setTab(CREATE_GAME_TAB);
                }
                else {
                    setTab(LIVE_GAME_TAB);
                }
            }
        }
        else {
            setTab(DAILY_TAB);
        }
        chooseGame({
            time,
            inc,
            variant,
            modeId: getModeId(time, variant, timeControl),
            turnLength,
            timeControl,
        });
    }
    catch (e) {
        chooseGame({
            time: 10,
            inc: 0,
            variant: VARIANT_CHESS,
            modeId: ModeIds.RAPID,
            timeControl: TimeControl.LIVE,
            turnLength: 2,
        });
    }
}
function initSettings() {
    try {
        const chessSet = localStorage.getItem("chessSet");
        if (chessSet) {
            setSetting({ name: "chessSet", val: chessSet });
        }
    }
    catch (e) {
        console.log(e);
    }
}
async function growthbookExperiments() {
    await growthbook.loadFeatures({ autoRefresh: true });
    //Check if first visit
    // const visitedBefore = localStorage.getItem("chosenTime");
    // if (!visitedBefore) {
    //   if (growthbook.isOn("staunty")) {
    //     localStorage.setItem("chessSet", ChessSets.STAUNTY);
    //     setSetting({ name: "chessSet", val: ChessSets.STAUNTY });
    //   }
    // }
}
function isIE() {
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
}
function ErrorFallback({ error, resetErrorBoundary }) {
    return (<Popup onClose={null} title="Something went wrong">
      <div className="flex flex-col items-center justify-center w-full pt-4 mt-4">
        <div className="mb-6">{error.message}</div>
        <button className="btn btn-base btn-blue" onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </Popup>);
}
const myErrorHandler = (error: Error, info: {
    componentStack: string;
}) => {
    setError(ERROR_BOUNDRY);
    setErrorMsg({
        errorMsg: "version9 " + error.message,
        componentStack: info.componentStack,
    });
    sendPingData();
};
function MyApp({ Component, pageProps, userData }) {
    const router = useRouter();
    const currentPath = router.pathname;
    const gamePath = currentPath.startsWith("/game") ||
        currentPath.startsWith("/daily/game") ||
        currentPath.startsWith("/analysis");
    const iframePath = currentPath.startsWith("/iframe");
    const [ie11, setIe11] = useState(false);
    useEffect(() => {
        if (!websocket) {
            signInStatus();
            // initWebsocketConnect();
        }
        initLobbyChosenGame();
        initSettings();
        if (process.env.NODE_ENV == "production") {
            consentListener();
            loadAds();
        }
        removeServiceWorker();
        if (isIE()) {
            setIe11(true);
        }
        growthbookExperiments();
        if (process.env.NODE_ENV === "production") {
            initWebPush();
        }
        setBoardSize();
        function onUserInteraction() {
            window.userHasInteracted = true;
            document.removeEventListener("click", onUserInteraction);
            document.removeEventListener("mousedown", onUserInteraction);
            document.removeEventListener("touchstart", onUserInteraction);
            console.log("User has interacted with the site. Sounds can now be played.");
        }
        document.addEventListener("touchstart", onUserInteraction);
        document.addEventListener("mousedown", onUserInteraction);
        document.addEventListener("click", onUserInteraction);
        return () => {
            document.removeEventListener("click", onUserInteraction);
            document.removeEventListener("mousedown", onUserInteraction);
            document.removeEventListener("touchstart", onUserInteraction);
        };
    }, []);
    useEffect(() => {
        const handleStart = (url: string) => {
            setLoadingBar(true);
        };
        const handleStop = (url: string) => {
            setLoadingBar(false);
            pageview(url);
            growthbook.setURL(window.location.href);
        };
        const handleError = () => {
            setLoadingBar(false);
        };
        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleStop);
        router.events.on("routeChangeError", handleError);
        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleStop);
            router.events.off("routeChangeError", handleError);
        };
    }, [router.events]);
    return (<>
      {/*
        //@ts-ignore */}
      <Provider>
        <Head>
          <meta name="viewport" content="viewport-fit=cover, width=device-width, height=device-height, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no"/>
        </Head>
        {!iframePath && <Navigation />}
        {ie11 ? (<HomeIE />) : (<ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
                window.location.reload();
            }} onError={myErrorHandler}>
            <GrowthBookProvider growthbook={growthbook}>
              <Component {...pageProps}/>
            </GrowthBookProvider>
          </ErrorBoundary>)}
        {!gamePath && !iframePath && <Footer />}
      </Provider>
    </>);
}
const __Next_Translate__Page__195049fda57__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__195049fda57__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  