import { api } from "../services/api";
import { createReduxModule } from "hooks-for-redux";
import { userStore } from "./user";
import { ChessSets } from "@chesshotel/constants";
import { getSize } from "../util/boardSize";
import { gaEvent } from "../lib/ga";

const initialState = {
  sound: true,
  autoQueening: false,
  minOpponentRating: 0,
  maxOpponentRating: 3000,
  activePage: 0,
  noGuests: false,
  pieceDestinations: true,
  lastMove: true,
  chessSet: ChessSets.CHESSHOTEL,
  boardSize: null,
  isDesktop: null,
};

export const [
  useSettings,
  { setSetting, doSetSettings, doSetBoardSize, reset },
  settingsStore,
] = createReduxModule("settings", initialState, {
  setSetting: (state, { name, val }) => ({
    ...state,
    [name]: val,
  }),
  doSetSettings: (state, settings) => ({
    ...state,
    ...settings,
  }),
  doSetBoardSize: (state, { boardSize, isDesktop }) => ({
    ...state,
    boardSize,
    isDesktop,
  }),
  reset: (state) => ({
    ...initialState,
    boardSize: state.boardSize,
    isDesktop: state.isDesktop,
  }),
});

// export function initChessSetSettings() {
//   const chessSet = localStorage.getItem("chessSet");
//   if (chessSet) {
//     setSetting({ name: "chessSet", val: chessSet });
//   }
// }

let didSend = false;

export function setBoardSize() {
  const { boardSize, isDesktop } = settingsStore.getState();

  function updateChessboardSize() {
    const size = getSize();

    if (!didSend && size.isDesktop !== null) {
      if (size.isDesktop) {
        gaEvent({ category: "boardSize", action: "desktop" });
      } else {
        gaEvent({ category: "boardSize", action: "mobile" });
      }
      didSend = true;
    }

    if (size.boardSize !== boardSize || size.isDesktop !== isDesktop) {
      doSetBoardSize({ boardSize: size.boardSize, isDesktop: size.isDesktop });
    }
  }

  if (boardSize === null || isDesktop === null) {
    window.addEventListener("resize", updateChessboardSize);
    window.addEventListener("orientationchange", () => {
      updateChessboardSize();
    });
    updateChessboardSize();
  }
}

export function setSettings(settingsArr) {
  const settings: any = {};
  settingsArr.forEach((item) => {
    settings[item.name] = item.value;
  });

  const settingsState = settingsStore.getState();

  const sound = settings.sound ? settings.sound === "true" : true;
  const autoQueening = settings.autoQueening
    ? settings.autoQueening === "true"
    : false;
  // const time = settings.time ? parseInt(settings.time) : 10;
  // const inc = settings.inc ? parseInt(settings.inc) : 0;
  // const variant = settings.variant ? parseInt(settings.variant) : VARIANT_CHESS;

  const minOpponentRating = settings.minOpponentRating
    ? parseInt(settings.minOpponentRating)
    : 0;
  const maxOpponentRating = settings.maxOpponentRating
    ? parseInt(settings.maxOpponentRating)
    : 3000;
  const noGuests = settings.noGuests ? settings.noGuests === "true" : false;

  const pieceDestinations = settings.pieceDestinations
    ? settings.pieceDestinations === "true"
    : settingsState.pieceDestinations;
  const lastMove = settings.lastMove
    ? settings.lastMove === "true"
    : settingsState.lastMove;

  const chessSet = settings.chessSet || ChessSets.CHESSHOTEL;

  doSetSettings({
    sound,
    autoQueening,
    // time,
    // inc,
    // variant,
    minOpponentRating,
    maxOpponentRating,
    noGuests,
    pieceDestinations,
    lastMove,
    chessSet,
  });
}

export function updateSetting(name, val) {
  const { memberLoggedIn } = userStore.getState();

  if (memberLoggedIn && val != null) {
    const value = val.toString();
    localStorage.setItem(name, value);
    api.post("/setting/update", { name, value }).catch(() => {
      console.log("error in function updateSetting");
    });
  }
  setSetting({ name, val });
}
