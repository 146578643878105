// import { GA_TRACKING_ID, RAMP_GA4 } from "./lib/ga";

export function consentListener() {
  // window.gtag("consent", "default", {
  //   ad_storage: "denied",
  //   ad_user_data: "denied",
  //   ad_personalization: "denied",
  //   analytics_storage: "denied",
  // });

  // const timeOut = setTimeout(() => updateGAConsent(null), 300);

  // Listen for consent changes from Playwire (Ramp.js)
  window.googlefc = window.googlefc || {};
  window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];
  window.googlefc.callbackQueue.push({
    CONSENT_DATA_READY: () =>
      window.__tcfapi("addEventListener", 2.2, (data, success) => {
        if (success) {
          // clearTimeout(timeOut);
          updateGAConsent(data.purpose.consents);
        }
      }),
  });
}

// Function to update Google Analytics consent settings
function updateGAConsent(purposeConsents) {
  const consentData = {
    analytics_storage: purposeConsents[1] ? "granted" : "denied",
    ad_storage: purposeConsents[2] ? "granted" : "denied",
    ad_personalization: purposeConsents[4] ? "granted" : "denied",
    ad_user_data: purposeConsents[7] ? "granted" : "denied",
  };
  // : {
  //     analytics_storage: "granted",
  //     ad_storage: "granted",
  //     ad_personalization: "granted",
  //     ad_user_data: "granted",
  //   };

  window.gtag("consent", "update", consentData);
}
